<template>
	<q-card class="q-pa-md">
		<div>
			<div class="row">
				<q-input
					class="col-12 col-sm-6 q-px-sm q-my-sm"
					label="Subdomain Name"
					v-model="incident_template.subdomain"
					:error="has_subdomain_error"
					:error-message="subdomain_error_messsage"
					:disable="incident_template.is_inherited"
				/>
				<q-input
					class="col-12 col-sm-6 q-px-sm q-my-sm"
					label="Subdomain URL"
					v-model="subdomain_url"
					ref="subdomain_url"
					readonly
				>
					<template v-slot:append>
						<q-btn flat icon="o_copy" @click="copyUrl" class="cursor-pointer copy-btn" />
					</template>
				</q-input>
			</div>
			<div class="row">
				<q-select
					class="col-12 col-sm-6 q-px-sm q-my-sm"
					v-model="incident_template.form"
					:options="form_choices"
					label="Select form"
					:option-label="(item) => item.name"
					:disable="incident_template.is_inherited"
					:error="has_choice_error"
					:error_message="choice_error_message"
				/>
				<q-input
					class="col-12 col-sm-6 q-px-sm q-my-sm"
					label="Name"
					autogrow
					v-model="incident_template.customer_name"
					:disable="incident_template.is_inherited"
					:error="has_name_error"
					:error-message="name_error_message"
				/>
			</div>
			<q-item tag="label" class="q-mb-md">
				<q-item-section>
					<q-item-label>Apply custom branding</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="enable_branding" :disable="incident_template.is_inherited" />
				</q-item-section>
			</q-item>

			<IncidentTemplateBranding
				v-if="enable_branding"
				class="template-items"
				:disable="incident_template.is_inherited"
				:branding="setBranding()"
				@update="updateBranding"
			/>
		</div>
		<q-card-actions align="right">
			<q-btn
				flat
				label="Save"
				color="primary"
				@click="update_dialog=true"
			/>
			<q-btn
				flat
				label="Delete"
				color="primary"
				@click="delete_dialog=true"
			/>
		</q-card-actions>
		<q-dialog v-model="delete_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span
						>Are you sure you want to delete <b>{{ incident_template.subdomain }}</b
					>?</span>
				</q-card-section>
				<q-card-actions align='right'>
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Confirm" color="primary" @click="deleteIncidentTemplate"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="update_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span>
						<b >WARNING:</b> editing this subdomain will change the URL for submitting web forms for this template.
						<br>
						Do you wish to continue?
					</span>
				</q-card-section>
				<q-card-actions align='right'>
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Continue" color="primary" @click="updateIncidentTemplate"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</q-card>
</template>

<script>
import IncidentTemplateBranding from "@/components/incidents/templates/IncidentTemplateBranding.vue"
import IncidentTemplateAPI from "@/services/api/IncidentTemplate.js";
import utils from "@/services/utils.js"
import { mapActions, mapGetters } from "vuex";
import  helpers from '@/utils/helpers_incidents.js'

export default {
	name: "IncidentTemplate",
	components: {
		IncidentTemplateBranding,
	},
	props: {
		incident_template: Object,
	},
	data() {
		return {
			form_choices: [],
			enable_branding: false,
			prompt_message:{
				update: '',
			},
			old_subdomain: '',
			has_subdomain_error: false,
			has_choice_error: false,
			has_name_error: false,
			subdomain_error_messsage: '',
			choice_error_message: '',
			name_error_message: '',
			delete_dialog: false,
			update_dialog: false,
			has_new_logo: false,
			has_branding_error: false,
		}
	},
	computed: {
		...mapGetters(['forms', 'current_CG']),
		subdomain_url: function () {
			return `${this.old_subdomain}.alertcascade.net/incident-template/`
		},
		has_branding: function () {
			return (!!this.incident_template.template_branding &&
				Object.entries(this.incident_template.template_branding)
					.filter(val => val[1]).length > 0)
		},
	},
	methods: {
		...mapActions(['listForms']),
		async deleteIncidentTemplate() {
			this.$q.loading.show({
				delay: 400,
			})
			const response = await IncidentTemplateAPI.deleteIncidentTemplate(this.incident_template.id)
			this.$q.loading.hide()
			if (response.error){
				this.notify(response.data)
				return
			}
			this.$q.notify('Template deleted successfully.')
			this.$emit("deleteIncidentTemplate", this.incident_template.id);
		},
		async updateIncidentTemplate(){
			this.update_dialog = false
			if (!this.enable_branding) {
				this.$delete(this.incident_template, "template_branding");
			}
			if (this.enable_branding && this.has_new_logo){
				await this.updateLogo()
			}
			let has_errors = this.validate()
			if (has_errors || this.has_branding_error){
				return
			}
			this.$q.loading.show({
				delay: 400,
			})
			let updated_template = await IncidentTemplateAPI.updateIncidentTemplate(this.incident_template);
			this.has_new_logo = false
			this.$q.loading.hide()
			if (updated_template.error){
				this.notify(updated_template.data)
				return
			}
			this.$emit("updateIncidentTemplate", updated_template);
			this.old_subdomain = updated_template.data.subdomain
			this.$q.notify('Template updated successfully.')
			this.has_new_logo = false
		},
		copyUrl() {
			utils.copyToClipboard(this.subdomain_url) && this.$refs.subdomain_url.select() && console.log('COPY!');
			this.$q.notify('URL copied successfully.')
		},
		setBranding() {
			let branding = {};
			if (this.enable_branding == true && !this.has_branding) {
				branding = {
					background_color: null,
					content_color: null,
					content_size: null,
					label_color: null,
					label_size: null,
					title_color: null,
					title_size: null,
					logo: null
				};
			} else {
				branding = this.incident_template.template_branding;
			}
			branding.template = this.incident_template.id;
			this.$set(this.incident_template, "template_branding", branding);
			return this.incident_template.template_branding;
		},
		updateBranding({ prop, value }) {
			this.has_branding_error = false
			if (prop == 'logo'){
				this.has_new_logo = true
			}
			this.incident_template.template_branding[prop] = value;
		},
		async updateLogo(){
			let logo_val = await this.uploadAttachment(this.incident_template.template_branding.logo)
			this.incident_template.template_branding.logo = logo_val[0].url
		},
		async uploadAttachment(files){
			let files_temp = [{
				name: files[0].name,
				customer_group: this.current_CG.customer_group.id
			}]
			let val = await utils.uploadAttachment(files_temp, files, true);
			return val;
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		validate(){
			this.resetValues()
			this.incident_template.subdomain = helpers.fix_input(this.incident_template.subdomain)
			this.subdomain_error_messsage = helpers.validate_subdomin(this.incident_template.subdomain, true)
			if (this.subdomain_error_messsage){
				this.has_subdomain_error = true
				return true
			}
			if (!this.incident_template.form){
				this.has_choice_error = true
				this.choice_error_message = 'Please select a form'
				return true
			}

			if (this.incident_template.name && this.incident_template.name.length > 50){
				this.has_name_error = true
				this.name_error_message = "The name must contain less than 50 characters"
				return true
			}
			return false
		},
		resetValues(){
			this.has_subdomain_error = this.has_choice_error = this.has_name_error = false
			this.subdomain_error_messsage = this.choice_error_message = this.name_error_message = ''
		}
	},
	async created() {
		this.old_subdomain = this.incident_template.subdomain
		await this.listForms();
		for(let i in this.forms){
			this.form_choices.push({id: this.forms[i].id, name: this.forms[i].name})
		}
		this.incident_template.form = this.forms
			.filter(choice => choice.id == this.incident_template.form)
			.map(form => ({id: form.id, name: form.name}))[0]
		this.enable_branding = this.has_branding;
	},
}
</script>
<style lang="scss">
	.copy-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
