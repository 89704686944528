<template>
	<div class="row justify-center">
		<div
			v-for="item in custom_branding_data" :key="item.label"
			:class="
				['background_color', 'title', 'logo'].includes(item.value_type) ? 
				'full-width q-mb-md q-px-sm' : 
				'col-12 col-sm-6 q-px-sm q-my-sm'
			"
		>
			<div v-if="item.value_type == 'title'" class="text-left">
				<b>{{item.label}}</b><hr>
			</div>
			<div v-else-if="item.value_type == 'logo'">
				<q-file
					class="full-width"
					v-model="new_logo"
					:label='item.label'
					outlined
					color="primary"
					multiple
					max-files="1"
					:hint="'Old Logo: ' + old_logo"
					clerable
					accept=".png, .jpg"
					@input="updateBranding(new_logo, item.value_type, item.type)"
					:disable="disable"
					:error="item.error"
					:error-message="item.error_message"
				>
					<template v-slot:prepend>
						<q-icon name="o_attach_file" />
					</template>
				</q-file>
			</div>
			<q-input
				v-else-if="item.type == 'size'"
				outlined
				:label="item.label"
				v-model="item.value"
				:hint="item.hint"
				:disable="disable"
				type="number"
				onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
				@input="updateBranding(item.value, item.value_type, item.type)"
			/>
			<q-input
				v-else-if="item.type == 'colour'"
				v-model="item.value"
				filled
				:hide-bottom-space="item.error ? false : true"
				@input="updateBranding(item.value, item.value_type, item.type)"
				:style="{ borderBottom: `10px solid ${item.value}` }"
				:disable="disable"
				:label="item.label"
				:error="item.error"
				:error-message="item.error_message"
			>
				<template v-slot:append>
					<q-btn flat icon="colorize" class="cursor-pointer colour-btn">
						<q-popup-proxy transition-show="scale" transition-hide="scale">
							<q-color
								format-mode="hex"
								v-model="item.value"
								@input="updateBranding(item.value, item.value_type, item.type)"
							/>
						</q-popup-proxy>
					</q-btn>
				</template>
			</q-input>
		</div>
		<p><em>Empty and incorrect values will be replaced with our defaults.<br>
			Reload this tab in order to revoke your unsaved changes.</em></p>
	</div>
</template>

<script>
import  helpers from '@/utils/helpers_incidents.js'
export default {
	name: "IncidentTemplateBranding",
	props: {
		branding: Object,
		disable: Boolean,
	},
	data(){
		return{
			old_logo: '',
			new_logo: null,

			has_background_color_error: false,
			has_title_color_error: false,
			has_content_color_error: false,
			has_label_color_error: false,
			background_color_error_message: '',
			title_color_error_message: '',
			content_color_error_message: '',
			label_color_error_message: '',
		}
	},
	computed: {
		custom_branding_data(){
			return [
				{
					value_type: 'title',
					label: 'Background'
				},
				{
					label: "Background colour",
					value: this.branding.background_color,
					value_type: 'background_color',
					type: 'colour',
					hint: 'Colour code (hex)',
					error: this.has_background_color_error,
					error_message: this.background_color_error_message,
				},
				{
					value_type: 'title',
					label: 'Title'
				},
				{
					label: "Title colour",
					value: this.branding.title_color,
					value_type: 'title_color',
					type: 'colour',
					hint: 'Colour code (hex)',
					error: this.has_title_color_error,
					error_message: this.title_color_error_message,
				},
				{
					label: "Title size",
					value: this.branding.title_size,
					value_type: 'title_size',
					type: 'size',
					hint: 'Font Size (px)',
				},
				{
					value_type: 'title',
					label: 'Content'
				},
				{
					label: "Content colour",
					value: this.branding.content_color,
					value_type: 'content_color',
					type: 'colour',
					hint: 'Colour code (hex)',
					error: this.has_content_color_error,
					error_message: this.content_color_error_message,
				},
				{
					label: "Content size",
					value: this.branding.content_size,
					value_type: 'content_size',
					type: 'size',
					hint: 'Font Size (px)',
				},

				{
					value_type: 'title',
					label: 'Labels'
				},
				{
					label: "Labels colour",
					value: this.branding.label_color,
					value_type: 'label_color',
					type: 'colour',
					hint: 'Colour code (hex)',
					error: this.has_label_color_error,
					error_message: this.label_color_error_message,
				},
				{
					label: "Labels size",
					value: this.branding.label_size,
					value_type: 'label_size',
					type: 'size',
					hint: 'Font Size (px)',
				},
				{
					value_type: 'title',
					label: 'Logo Attachment'
				},
				{
					label: "Logo",
					value_type: 'logo',
					type: 'logo',
					hint: 'Image File',
					error: this.has_logo_error,
					error_message: this.logo_error_message,
				},
			]
		},
	},
	methods: {
		async updateBranding(value, prop, type) {
			if (type == 'logo') {
				value = !value ? [] : value;
			}
			if (type == 'colour'){
				this['has_' + prop + "_error"] = false
				this[prop + '_error_message'] = helpers.validate_hex(value)
				if (this[prop + '_error_message']){
					this['has_' + prop + "_error"] = true
				}
			}
			this.$emit('update', { prop, value });
		},
	},
	created(){
		if (this.branding.logo){
			this.old_logo = this.branding.logo
		}
	}
};
</script>
<style lang="scss">
	.colour-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
