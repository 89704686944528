<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<div class="text-h5">Add new template</div>
		</q-card-section>
		<q-item>
			<q-input
				class="full-width"
				v-model="incident_template.subdomain"
				label="Enter subdomain"
				:error="has_subdomain_error"
				:error-message="subdomain_error_message"
			/>
		</q-item>
		<q-item>
			<q-select
				class="full-width"
				v-model="incident_template.form"
				:options="form_choices"
				label="Select form"
				:option-label="(item) => item.name"
				:error="has_form_error"
				:error-message="form_error_message"
			/>
		</q-item>
		<q-card-actions align='right' class="q=px-none">
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn flat label="Add" color="primary" @click="addIncidentTemplate()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import IncidentTemplatesAPI from "@/services/api/IncidentTemplate.js";
import { mapActions, mapGetters } from "vuex";
import { required } from 'vuelidate/lib/validators';
import { subdomain } from '@/services/validators.js'
import  helpers from '@/utils/helpers_incidents.js'


export default {
	name: "IncidentFormAdd",
	data () {
		return{
			incident_template : {
				"subdomain": ""
			},
			form_choices: [],
			has_subdomain_error: false,
			has_form_error: false,
			subdomain_error_message: '',
			form_error_message: '',
		}
	},
	computed:{
		...mapGetters(['current_CG', 'forms']),
	},
	validations:{
		incident_template:{
			subdomain:{
				required,
				subdomain
			}
		}
	},
	methods: {
		...mapActions(['listForms']),
		async addIncidentTemplate() {
			this.$q.loading.show({
				delay: 400,
			})
			let has_errors = this.hasErrors()
			if(has_errors){	return;	}

			this.incident_template['customer_group'] = this.current_CG.customer_group.id
			let response = await IncidentTemplatesAPI.createIncidentTemplate(this.incident_template);
			if (response.statusCode==400 || response.statusCode==404) {
				this.$q.notify({
					timeout: 6700,
					message: response.data,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return;
			}	
			this.$emit('newIncidentTemplate', response)
			this.$q.loading.hide()
			this.$emit('close')
			this.$q.notify('New template created successfully.')
			this.incident_template = {"subdomain": ""}
		},
		hasErrors(){
			this.has_subdomain_error = this.has_form_error = false
			this.subdomain_error_message = this.form_error_message = ''
			this.incident_template.subdomain = helpers.fix_input(this.incident_template.subdomain)
			this.subdomain_error_message = helpers.validate_subdomin(this.incident_template.subdomain, true)

			if (this.subdomain_error_message){
				this.has_subdomain_error = true
				return true
			}
			if (!this.incident_template.form){
				this.has_form_error = true
				this.form_error_message = 'Please select a form for the template'
				return true
			}
			return false
		},
	},
	async created(){
		await this.listForms();
		for(let i in this.forms){
			this.form_choices.push({id :this.forms[i].id, name: this.forms[i].name })
		}
	},

}

</script>