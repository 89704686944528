
<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Templates
		</h1>
		<q-list :bordered="incident_templates.length > 0" class="rounded-borders text-left">
			<q-expansion-item
				v-for="incident_template in incident_templates"
				:key="incident_template.id"
				expand-separator
				:label="incident_template.subdomain"
				icon="o_dns"
			>
				<IncidentTemplate
					:incident_template="incident_template"
					@deleteIncidentTemplate="deleteIncidentTemplate"
					@updateIncidentTemplate="updateIncidentTemplate"
				/>
			</q-expansion-item>
		</q-list>
		<q-dialog v-model="add_template">
			<IncidentTemplateAdd 
				@newIncidentTemplate="newIncidentTemplate"
				@close="add_template = false"
			/>
		</q-dialog>
		<div class="field-fab-wrapper q-pt-md">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="add"
				label="Add Template"
				rounded
				@click="() => (add_template = true)"
			/>
			<q-btn
				v-else
				size="lg"
				color="primary"
				icon="add"
				round
				@click="() => (add_template = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add Template
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>

import IncidentTemplate from '@/components/incidents/templates/IncidentTemplate.vue';
import IncidentTemplateAdd from '@/components/incidents/templates/IncidentTemplateAdd.vue';
import IncidentTemplateAPI from "@/services/api/IncidentTemplate.js";
import { mapGetters } from 'vuex';

export default {
	name: "IncidentTemplateList",
	components: {
		IncidentTemplate,
		IncidentTemplateAdd,
	},
	data(){
		return{
			incident_templates: [],
			add_template: false
		}
	},
	computed:{
		...mapGetters(['template_errors', 'current_CG', 'logged_user'])
	},
	methods: {
		deleteIncidentTemplate(incident_template){
			this.incident_templates = this.incident_templates.filter(template => template.id != incident_template);
		},
		updateIncidentTemplate(updated_template){
			let template_id = "";
			for(let i in this.incident_templates){
				if(this.incident_templates[i].id == updated_template.id){
					template_id = i;
				}
			}
			if (template_id){
				this.$set(this.incident_templates, template_id, updated_template)
			}
		},
		newIncidentTemplate(incident_template){
			for (let i in this.incident_templates){
				this.incident_templates[i].is_open = false;
			}
			this.incident_templates.push(incident_template)
		},
	},
	async created() {	
		this.$q.loading.show({
			delay: 400
		})
		this.incident_templates = await IncidentTemplateAPI.listIncidentTemplates()
		if(this.incident_templates.error){
			this.incident_templates = []
		}else{
			for (let i in this.incident_templates){
				this.incident_templates[i].is_open = false;
				this.incident_templates[i].is_inherited = this.current_CG.customer_group.parent && this.incident_templates[i].customer_group !== this.logged_user.customer_group
			}
		}
		this.$q.loading.hide()
	}
	
}
</script>

<style scoped>
.add-new-template{
	margin-top: 10px;
}

</style>
