export default {
	validate_subdomin(subdomain, required){
		let subdomain_error_message = ''
		subdomain_error_message = (required && !subdomain) ? 'Please enter subdomain for the template' :
			(!subdomain) ? `${subdomain} is not a valid subdomain.` : 
			(subdomain.length > 20) ? 'The subdomain name must contain less than 20 characters' : 
			(!subdomain.match(/^[\w\d\-\s_]+$/i)) ? `${subdomain} is not a valid subdomain.` : '';
		return subdomain_error_message
	},
	fix_input(subdomain){
		return subdomain.toLowerCase().trim().replace(' ', '-').replace("_", '-')
	},
	validate_hex(value){
		let error_message = ''
		var reg=/^#([0-9a-f]{3}){1,2}$/i;
		if (!reg.test(value)){
			error_message = `${ value } is not a valid hex colour code.`
		}
		return error_message
	}
}